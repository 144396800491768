import React, { useEffect, useState } from "react";
import avater from "../../images/avater.png";
import { MdEmail } from "react-icons/md";
import { AiOutlineArrowRight } from "react-icons/ai";
import info from "../../images/68d5e54.svg";
import deposit from "../../images/fae87e4.svg";
import withdraw from "../../images/a723444.svg";
import history from "../../images/2084950.svg";
import account from "../../images/88ac34a.svg";
import vip from "../../images/cc6e80b.svg";
import transection from "../../images/4a9ab9a.svg";
import teamReport from "../../images/eb36604.svg";
import message from "../../images/81f967c.svg";
import inviteFriends from "../../images/04c663c.svg";
import Navber from "../Navber/Navber";
import { Link, useNavigate } from "react-router-dom";
import { authkey } from "../Login/authkey";
import wheelSpin from "../../images/wheelSpinBgrmv.png";
import usdt from "../../images/usdt.png";
import smallLogo from "../../images/user.png";
import { useSelector, useDispatch } from "react-redux";
import { updateSummary } from "../../store/slice";
import { updateUser } from "../../store/slice";
import { updateDashboardMessage } from "../../store/slice";
import { apiUrl } from "../Login/baseurl";
const Profile = () => {
  const [dashboardData, setDashBoardData] = useState({});
  const [dashboardDataPack, setDashBoardDataPack] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  var dashboard = new FormData();
  dashboard.append("dashboard", "");
  dashboard.append("auth", authkey);
  dashboard.append("logged", localStorage.getItem("auth"));
  var dashboardProfile = new FormData();
  dashboardProfile.append("dashboard", "");
  dashboardProfile.append("auth", authkey);
  dashboardProfile.append("logged", localStorage.getItem("auth"));

  var logoutUserData = new FormData();
  logoutUserData.append("logout", "");
  logoutUserData.append("auth", authkey);
  logoutUserData.append("logged", localStorage.getItem("auth"));

  useEffect(() => {
    setLoading(true);
    fetch(apiUrl, {
      method: "POST",
      body: dashboard,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status == 200) {
          dispatch(updateSummary(data.message.pack));
          dispatch(updateUser(data.message.user));
          dispatch(updateDashboardMessage(data.message));

          setDashBoardData(data.message);
          setDashBoardDataPack(data.message.pack);
          setLoading(false);
        } else {
          navigate("/login");
        }
      });
  }, []);

  useEffect(() => {
    

    fetch(apiUrl, {
      method: "POST",
      body: dashboardProfile,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status == 200) {
          dispatch(updateDashboardMessage(data.message));

          
       
        } else {
          navigate("/login");
        }
      });
  }, []);

  var dashboardMessagex = useSelector(
    (state) => state.dashboardmessage.message
  );

  const logout = () => {
    fetch(apiUrl, {
      method: "POST",
      body: logoutUserData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status == 200) {
          localStorage.removeItem("auth");

          navigate("/login");
        }
      });
  };

  const dashboardMessage = useSelector(
    (state) => state.dashboardmessage.message
  );


  return (
    <>
      <div className="container max-w-[1080px] mx-auto">
        <div className="w-full text-white h-[150px] outline  relative rounded-b-[50%]" style={{ backgroundImage: `url(/files/bg1.jpg)`, backgroundRepeat: "no-repeat",
          backgroundSize: "cover"}}>
        
          <div className="  pt-5" >
              <div className="w-[50%] mx-auto flex  justify-center items-center " style={{minWidth:"255px",background: "rgb(7 10 6 / 68%)",
            boxShadow: "rgb(144 195 153 / 92%) 1px 1px 5px 3px",
            borderRadius: "100px"}}>
            <div className="flex gap-5 " >
              <div className="avatar">
                <div className="w-[100px] rounded-full ">
                  {Object.entries(dashboardMessagex).length === 0 ? (
                    <img src={avater} alt="" />
                  ) : (
                    <img
                      src={`/files/badges/${dashboardMessagex.user[0].packid}.png`}
                      alt=""
                    />
                  )}
                </div>
              </div>
              <div>
                <h2 className="card-title mt-6">
                  {" "}
                  {
                    //dashboardMessage.user[0].username
                    //dashboardMessagex.user[0].username
                    Object.entries(dashboardMessagex).length === 0
                      ? "user name"
                      : dashboardMessagex.user[0].username
                  }
                </h2>
                <p>
                  ID:{" "}
                  {Object.entries(dashboardMessagex).length === 0
                    ? "user name"
                    : dashboardMessagex.user[0].invite}
                </p>
              </div>
            </div>
            </div>
          </div>
        </div>
        {loading  && Object.entries(dashboardMessagex).length === 0? (
            <div className="text-center">
              <svg
                role="status"
                className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          ) : null}
        <div className="w-[90%] outline text-white mx-auto m-5 p-5 grid grid-cols-2 md:grid-cols-5 gap-5 rounded-lg" style={{ backgroundImage: `url(/files/bgimg.jpg)`}}>
          <Link
            to="/team-report/agent"
            className="flex flex-col items-center text-center" style={{background: "#1b5204ad",
              boxShadow: "1px 1px 5px 3px #08a322eb",
              borderRadius: "100px"}}
          >
            <img src={teamReport} alt="" />
            <h1>Team report</h1>
          </Link>

          <Link
            to="/lucky-spin"
            className="flex flex-col items-center text-center" style={{background: "#1b5204ad",
            boxShadow: "1px 1px 5px 3px #08a322eb",
            borderRadius: "100px"}}
          >
            <img className="w-10 h-6 rounded " src={wheelSpin} alt="" />

            <h1 className="">Wheel of Fortune</h1>
          </Link>
          <Link
            to="/grab-history"
            className="flex flex-col items-center text-center" style={{background: "#1b5204ad",
            boxShadow: "1px 1px 5px 3px #08a322eb",
            borderRadius: "100px"}}
          >
            <img src={history} alt="" />
            <h1>Grabs & Profits History</h1>
          </Link>
          <Link
            to="/personal-info"
            className="flex flex-col items-center text-center" style={{background: "#1b5204ad",
            boxShadow: "1px 1px 5px 3px #08a322eb",
            borderRadius: "100px"}}
          >
            <img src={info} alt="" />
            <h1>Profile</h1>
          </Link>
         
          <Link
            to="/invite-friends"
            className="flex flex-col items-center text-center" style={{background: "#1b5204ad",
            boxShadow: "1px 1px 5px 3px #08a322eb",
            borderRadius: "100px"}}
          >
            <img src={inviteFriends} alt="" />
            <h1>Grow your team</h1>
          </Link>

          <Link
            to="/membership"
            className="flex flex-col items-center text-center" style={{background: "#1b5204ad",
            boxShadow: "1px 1px 5px 3px #08a322eb",
            borderRadius: "100px"}}
          >
            <img src={vip} alt="" />
            <h1>Membership</h1>
          </Link>

          <Link
            to="/withdrawal-history"
            className="flex flex-col items-center text-center" style={{background: "#1b5204ad",
            boxShadow: "1px 1px 5px 3px #08a322eb",
            borderRadius: "100px"}}
          >
            <img src={transection} alt="" />
            <h1>Transaction History</h1>
          </Link>
<div style={{background: "#1b5204ad",
              boxShadow: "1px 1px 5px 3px #08a322eb",
              borderRadius: "100px",
              paddingLeft: "36%"}} >
          <div className="indicator m-auto" >
            <Link
              to="/message"
              className="flex flex-col items-center text-center" 
            >
              <img src={message} alt="" />
              <small className="indicator-item badge bg-red-400 rounded-full p-1">
                <small>{dashboardMessagex.notify}</small>
              </small>
              <h1>Inbox</h1>
            </Link>
          </div>
          </div>
          <div className="" style={{background: "#1b5204ad",
              boxShadow: "1px 1px 5px 3px #08a322eb",
              borderRadius: "100px"}}>
            <Link
              to="/rules"
              className="flex flex-col items-center text-center" 
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                />
              </svg>
              <h1>Rules</h1>
            </Link>
          </div>

          <div
            className="flex flex-col items-center  text-center cursor-pointer" style={{background: "#1b5204ad",
            boxShadow: "1px 1px 5px 3px #08a322eb",
            borderRadius: "100px"}}
            onClick={logout}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
              />
            </svg>
            <h1>Logout</h1>
          </div>
        </div>
        {loading && Object.entries(dashboardMessagex).length === 0 ? (
            <div className="text-center">
              <svg
                role="status"
                className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          ) : null}
        <div  className="w-[90%] mb-24 text-white card mx-auto gap-5 rounded-lg outline   shadow-xl "  style={{ backgroundImage: `url(/files/bgimg.jpg)`, backgroundRepeat: "no-repeat",
          backgroundSize: "cover" }}>
          <div className="card-body">
            <div className="flex justify-between p-1 pl-2 pr-2 rounded-lg "  style={{background: "#1b5204ad",
              boxShadow: "1px 1px 5px 3px #08a322eb",
              borderRadius: "100px"}}>
              <h1 className="text-sm md:text-xl ">Total Account Balance</h1>
              <h1 className="flex text-sm md:text-xl">
                {dashboardMessagex.asset}
                <img src={usdt} width="22" className="m-1 pt-[1px]" alt="" />
              </h1>
            </div>

            <div className="flex justify-between p-1 pl-2 pr-2 rounded-lg "  style={{background: "#1b5204ad",
              boxShadow: "1px 1px 5px 3px #08a322eb",
              borderRadius: "100px"}}>
              <h1 className="text-sm md:text-xl">Available Grab Balance</h1>
              <h1 className="flex text-sm md:text-xl">
                {dashboardMessagex.grab_balance}
                <img src={usdt} width="22" className="m-1 pt-[1px]" alt="" />
              </h1>
            </div>
            <div className="flex justify-between p-1 pl-2 pr-2 rounded-lg " style={{background: "#1b5204ad",
              boxShadow: "1px 1px 5px 3px #08a322eb",
              borderRadius: "100px"}}>
              <h1 className="text-sm md:text-xl">Today's Grab Profit</h1>
              <h1 className="flex text-sm md:text-xl">
                {dashboardMessagex.today_profit}
                <img src={usdt} width="22" className="m-1 pt-[1px]" alt="" />
              </h1>
            </div>
            <div className="flex justify-between p-1 pl-2 pr-2 rounded-lg "  style={{background: "#1b5204ad",
              boxShadow: "1px 1px 5px 3px #08a322eb",
              borderRadius: "100px"}}>
              <h1 className="text-sm md:text-xl">Total Grab Profit</h1>
              <h1 className="flex text-sm md:text-xl">
                {dashboardMessagex.totalgrab}
                <img src={usdt} width="22" className="m-1 pt-[1px]" />
              </h1>
            </div>
            <div className="flex justify-between p-1 pl-2 pr-2 rounded-lg "  style={{background: "#1b5204ad",
              boxShadow: "1px 1px 5px 3px #08a322eb",
              borderRadius: "100px"}}>
              <div className="flex ">
                <h1 className="text-sm md:text-xl">Staked Balance</h1>
              </div>
              <div className="flex ">
                <h1 className="flex text-sm md:text-xl">
                  {dashboardMessagex.locked_asset}
                  <img src={usdt} width="22" className="m-1 pt-[1px]" />
                </h1>
              </div>
            </div>
            <div className="flex justify-between pl-2 pr-2 rounded-lg p-1"  style={{background: "#1b5204ad",
              boxShadow: "1px 1px 5px 3px #08a322eb",
              borderRadius: "100px"}}>
              <h1 className="text-sm md:text-xl">Lifetime Staked Profit </h1>
              <h1 className="flex text-sm md:text-xl">
                {dashboardMessagex.stakeProfit}
                <img src={usdt} width="22" className="m-1 pt-[1px]" />
              </h1>
            </div>

            <div className="flex justify-between pl-2 pr-2 rounded-lg p-1"  style={{background: "#1b5204ad",
              boxShadow: "1px 1px 5px 3px #08a322eb",
              borderRadius: "100px"}}>
              <h1 className="text-sm md:text-xl">Lifetime Team Commission</h1>
              <h1 className="flex text-sm md:text-xl">
                {dashboardMessagex.promotion_bonus}
                <img src={usdt} width="22" className="m-1 pt-[1px]" />
              </h1>
            </div>
            <div className="flex justify-between pl-2 pr-2 rounded-lg p-1"  style={{background: "#1b5204ad",
              boxShadow: "1px 1px 5px 3px #08a322eb",
              borderRadius: "100px"}}>
              <h1 className="text-sm md:text-xl">Lifetime Account Profit </h1>
              <h1 className="flex text-sm md:text-xl">
                {dashboardMessagex.stakePlusGrab}{" "}
                <img src={usdt} width="22" className="m-1 pt-[1px]" />
              </h1>
            </div>
            
           </div>
           
        </div>
        
      
        
      </div>
      
      <div
        style={{
          display: "flex",
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 99,
        }}
        className="mt-5"
      >
        <Navber></Navber>
      </div>
    </>
  );
};

export default Profile;
