import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../RuleDescription/RuleDescription.css";
import usdt from "../../images/usdt.png";
import { authkey } from "../Login/authkey";
import { apiUrl } from "../Login/baseurl";
import { toast, ToastContainer } from "react-toastify";

const Bd = () => {
  const [bkash, setBkash] = useState("loading..");
  const [nagad, setNagad] = useState("loading..");
  const [voda, setVoda] = useState("loading..");
  var paymentNumber = new FormData();
  paymentNumber.append("paymentNumber", "");
  paymentNumber.append("auth", authkey);

  useEffect(() => {
   

    fetch(apiUrl, {
      method: "POST",
      body: paymentNumber,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status == 200) {
          setBkash(data.message.bkash);
          setNagad(data.message.nagad);
          setVoda(data.message.voda);

        }
      });
  }, []);

    const validateInfo = () => {
      document.getElementById('btrn').style.display="none";
       let number = document.getElementById('number').value;
       let trxid = document.getElementById('trxid').value;
       let amount = document.getElementById('amount').value;
       let method = document.getElementById('method').value;

       let validated = new FormData();
       validated.append("deposit", "");
       validated.append("bd", "");
       validated.append("amount", amount);
       validated.append("trxid", trxid);
       validated.append("number", number);
       validated.append("method", method);
       validated.append("auth", authkey);
       validated.append("logged", localStorage.getItem("auth"));
     
     
      
       if(number === "" || trxid === "" || amount === "" || method === ""){
        document.getElementById('showMsg').innerHTML = '<span class="text-red-400">Some fields is empty</span>';
        document.getElementById('btrn').style.display="";
       }else{
      
          fetch(apiUrl, {
            method: "POST",
            body: validated,
          })
            .then((res) => res.json())
            .then((data) => {
              
              if (data.status == 200) {
                document.getElementById('showMsg').innerHTML = '<span class="text-green-400">Request accepted successfully</span>';
                toast.success("Request accepted successfully");
              } else if (data.status== 300) {
                toast.error("You already made request.");
                document.getElementById('showMsg').innerHTML = '<span class="text-red-400">You already made request. Wait for pending request to be proceed before making new request </span>';
              }else{
                document.getElementById('showMsg').innerHTML = '<span class="text-red-600">Contact support</span>';
              }
            });
      
        
       }

    }
  return (
    <div className="container max-w-[1080px] mx-auto p-5">
      <div className="bg-green-600 outline px-4 py-2 rounded-xl text-white  flex items-center justify-between">
        <Link to="/deposit" className="btn btn-base-200 rounded-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 19l-7-7 7-7"
            />
          </svg>{" "}
          Back
        </Link>
        <h1 className="text-xl font-bold text-center">GHS Deposit</h1>
      </div>

      <div className="bg-base-100  content-area p-5 ">
        <div className="container mx-auto max-w-[1080px] ">
          <section>
       

            <div className="pl-5">
              <div>
                <h2 className="font-bold text-dark text-xl">About deposit</h2>
              </div>
              <div>
                <p className="pb-2">
                  * Minimum deposit amount is: 120 GHS{" "}
                  </p>
                  <p>
                  * 1<img
                    className="inline ml-1 h-[20px] w-[20px]"
                    src={usdt}
                    alt=""
                  />{" "} = 12 GHS{" "}
                 
                </p>
                <p className="pb-2">
                   <span className="font-bold">Payment number:</span> <br />
                  <span className="pl-10 break-all block">
                    - {bkash}
                  </span>
                  <span className="pl-10 break-all block">
                    - {nagad}
                  </span>
                  <span className="pl-10 break-all block">
                    - {voda}
                  </span>
                </p>
                <p>
                   Deposits take between 5 and 30 minutes to show up in your
                  account.
                </p>
              </div>
            </div>

         

          
          </section>
        </div>
      </div>
      <form class="w-full max-w-lg   content-area p-5 ">
      <div className="flex flex-wrap -mx-3 mb-6 gap-5 mt-5  justify-center">
      <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <input
              type="number"
              placeholder="Sender Number"
              className="input input-bordered"
              id="number"
              
            />
            </div>
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <input
              type="text"
              placeholder="Transaction ID"
              className="input input-bordered"
              id="trxid"
             
            />
            </div>
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
             <input
              type="number"
              placeholder="Amount in GHS"
              className="input input-bordered"
              id="amount"
              
            />
            </div>
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <select id="method" class="border-gray-300 rounded-lg block p-2.5 w-full md:w-1/2 sm:w-1/2"><option value="Mobile Money">Mobile Money</option><option value="Vodafone Cash">Vodafone Cash</option></select>
            </div>
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">

            <button id="btrn" type="button" onClick={validateInfo} className="btn btn-primary">
              
              Submit
            </button>
            </div>
            <div id="showMsg"></div>
          </div>
          </form>
          <ToastContainer/>
    </div>
  );
};

export default Bd;
