import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import bd from "../../files/bd.png";
import usdt from "../../files/usdt.png";

const dbmain = () => {






  return (
    <div className="container max-w-[1080px] mx-auto p-5">
      <div className="bg-green-600 outline px-4 py-2 rounded-xl   flex items-center justify-between">
        <Link to="/" className="btn btn-base-200 rounded-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 19l-7-7 7-7"
            />
          </svg>{" "}
          Back
        </Link>
        <h1 className="text-xl font-bold text-center">Withdraw</h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-5">
     
      <Link to="/withdraw/usdt">
      <div className="card   shadow-xl " 
             > 
             <img  src={usdt} alt="Crypto method"/>
             
                </div>
                </Link>
                <Link to="/withdraw/bd">
                <div className="card   shadow-xl " 
             > 
             <img  src={bd} alt="Bangladesh method"/>
             
                </div>
                </Link>
             
          
          
         
     
      </div>
    </div>
  );
};

export default dbmain;
