import React, { useState, useEffect } from 'react';
import { Wheel } from 'react-custom-roulette';
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import { authkey } from "../Login/authkey";
import { apiUrl } from "../Login/baseurl";
import usdt from "../../images/usdt.png";
import './Wheel4.css'


import { AiFillQuestionCircle } from 'react-icons/ai';

const Wheel4 = () => {
    const [loading, setLoading] = useState(false);
    const data = [
        { option: "0" },
        { option: "5" },
        { option: "10" },
      
        { option: "15" },
        { option: "20" },
        { option: "0" },
        { option: "30" },
        
        { option: "50" },
        { option: "80" },
        { option: "90" },

    ];

    

    const [winner, setWinner] = useState(0);

    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);
    const [winDataT, setWinDataT] = useState({});
    const [test, setTest] = useState(0);

    const [userName, setUserName] = useState(0);

    var dashboard = new FormData();
    dashboard.append("dashboard", "");
    dashboard.append("auth", authkey);
    dashboard.append("logged", localStorage.getItem("auth"));
    useEffect(() => {
        setLoading(true);
      fetch(apiUrl, {
        method: "POST",
        body: dashboard,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status == 200) {
            setLoading(false);
            setUserName(data.message.user[0]);
  
          }
        });
    }, []);



    const navigate = useNavigate();
    useEffect(() => {
        var history = new FormData();
        history.append("spinner", "");
        history.append("auth", authkey);
        history.append("logged", localStorage.getItem('auth'));
        fetch(apiUrl, {
            method: "POST",
            body: history,
        })
            .then((res) => res.json())
            .then((winHistory) => {
                if (winHistory.status == 200) {
                    setWinDataT(winHistory.message);

                } else {
                    navigate("/login");
                }
            });
    }, []);

    const runSpin = () => {
        var runSpinData = new FormData();
        runSpinData.append("runSpin", "");
        runSpinData.append("spinner", "");
        runSpinData.append("auth", authkey);
        runSpinData.append("logged", localStorage.getItem('auth'));
        fetch(apiUrl, {
            method: "POST",
            body: runSpinData,
        })
            .then((res) => res.json())
            .then((spHs) => {
                if (spHs.status == 200) {
                    setWinDataT(spHs.message);
                    if (spHs.message.winNumber == 'auto') {

                        const newPrize = Math.floor(Math.random() * data.length);
                        const newPrizeNumber = newPrize;
                        const item = data[newPrizeNumber];
                        setPrizeNumber(newPrizeNumber);
                        setMustSpin(true);
                        setWinner(item);
                      
                        if (newPrize != 0) {
                           
                            var eewrER = new FormData();
                            eewrER.append("winAmount", item.option);
                            eewrER.append("spinner", "");
                            eewrER.append("auth", authkey);
                            eewrER.append("logged", localStorage.getItem('auth'));
                            fetch(apiUrl, {
                                method: "POST",
                                body: eewrER,
                            })
                                .then((res) => res.json())
                                .then((sdfRTE) => {
                                    if (sdfRTE.status == 100) {
                                        toast.error(spHs.message);

                                    }else if(sdfRTE.status == 200) {
                                        toast.success(spHs.message);

                                    }else{
                                        
                                        toast.error(spHs.message);

                                    
                                    }

                                }, []);
                        }


                    } else {
                        const newPrize = spHs.message.winNumber;
                        const newPrizeNumber = newPrize;
                        const item = data[newPrizeNumber];
                        setPrizeNumber(newPrizeNumber);
                        setMustSpin(true);
                        setWinner(item);
                    }

                } else if (spHs.status == 100) {
                    toast.error(spHs.message.message);
                    setMustSpin(false);

                } else {
                    navigate("/login");
                }

            }, []);
    }





    const handleSpinClick = () => {

        if (test == 0 && winDataT?.spinLeft == 0) {
            setMustSpin(false)
            swal({
                title: "Are you sure?",
                text: "You will be charged 5 USDT fee for this spin",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        setTest(1);
                        runSpin();

                    } else {
                        setMustSpin(false)
                    }
                });
        } else {
            runSpin();

        }


        if (winDataT.spinLeft < 0) {

        }

    }


    return (
        <div className="container max-w-[1080] mx-auto ">
            <div className="bg-green-600 outline px-4 py-2 rounded-xl mt-5 mx-3 flex items-center justify-between">
                <Link to="/profile" className="btn btn-base-200 rounded-full px-2">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
                    </svg> Back
                </Link>
                <h1 className="text-xl font-bold text-center"> Wheel of Fortune</h1>
            </div>
            {loading ? (
            <div className="text-center">
              <svg
                role="status"
                className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          ) : null}
            <div className="wheel-decider">
                
                <div id="successInfo" className="my-4 text-xl text-center text-slate-800">
                    <span className="font-bold  ">Free Spins: </span>
                    {winDataT.spinLeft <= 0 ? <span className="font-bold lg:text-xl pt-5 pb-16 text-blue-800">0</span>

                        :
                        <span className="font-bold lg:text-xl pt-5 pb-16 text-blue-800"> {winDataT.spinLeft} </span>

                    }
                  
                </div>
            </div>

            <div className="wheel-decider pb-5" >
                <Wheel className="flex justify-center "
                    backgroundColors={["#081135", "#570C31"]}
                    textColors={["#ffffff"]}
                    // onStopSpinning={stopSpin}
                    radiusLineWidth={2}
                    outerBorderWidth={10}
                    mustStartSpinning={mustSpin}
                    prizeNumber={prizeNumber}

                    data={data}
                    onStopSpinning={() => {
                        setMustSpin(false);
                    }}
                />
            </div>

            <div className="wheel-decider">
                {winDataT.spinLeft <= 0 ?
                    <button type="button" disabled={mustSpin == true} onClick={handleSpinClick} className="btn btn-primary" >Spin 5<img className="inline ml-1 mt-1 h-[18px] w-[18px]" src={usdt} alt="" /></button>
                    :
                    <button disabled={mustSpin == true} className="btn btn-primary" onClick={handleSpinClick}>
                        Free Spin
                    </button>
                }
            </div>

            <div className="wheel-decider">
                <div >
                    <h2 className="text-2xl font-bold py-8">
                        {mustSpin == true
                            ? (<span className="pl-2">Spining...</span>)
                            : (<span className="pl-2">{winner.option ? "You win "+winner.option:<>Spin The Wheel</> }</span>)
                        }</h2>
                </div>
            </div>
            
            <div className="wheel-decider">
                <div className="text-slate-800 my-10 card w-full bg-white rounded shadow-xl mx-5">
                    <div className="card-body tracking-tighter">

                        <p className="text-justify">After you used your daily free spin, you can try your luck with more spins. Each spin will cost you 5<img className="inline ml-1 h-[19px] w-[19px]" src={usdt} alt="" /> which will automatically be deducted from your Grab Balance upon use.</p>
<small>
<p className="text-justify">- You get 1 free spin daily at 00:00 UTC</p>
                        <p className="text-justify">- You get 1 free spin for each 100<img className="inline ml-1 h-[19px] w-[19px]" src={usdt} alt="" /> you deposit.</p>
                        <p className="text-justify ">- You get 1 free spin for each 100<img className="inline ml-1 h-[19px] w-[19px]" src={usdt} alt="" /> deposited by referred layer one members  </p>
                      </small>                        <h2 className="card-title font-bold">
                            <span className="text-teal-900"> Good luck,</span>
                            <span className="text-blue-800"> {userName?.username}!</span></h2>

                    </div>
                </div>
            </div>

        </div>

    );
};

export default Wheel4;